import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-leasingVsMietkauf',
  templateUrl: './one-pager-leasingVsMietkauf.component.html'
})
export class OnePagerLeasingVsMietkaufComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Mietkauf und Leasing PV-Anlagen und Energiespeichern',
    'Mietkauf oder Leasing von LED Technik',
    'Mietkauf und Leasing von Infarotheizungen',
    'Mietkauf oder Leasing von E-Autos und E-Ladestationen',
    'Mietkauf oder Leasing von Drucklufttechnik',
    'Mietkauf oder Leasing von PKW und Nutzfahrzeugen uvm.'
  ];
  benefitPoints = [
    'sofortiger Kostenvorteil',
    'sichere Kalkulationsbasis',
    'Schonung von Liquidität, Eigenkapital und Kreditlinie',
    'Individuelle Vertragsgestaltung',
    'Herstellerunabhängige Finanzierung'
  ];
  preHeading = 'Die richtige Finanzierung wählen';
  heading = 'Leasing oder Mietkauf';
  headerTitle = 'Leasing oder Mietkauf';
  headerImgSrc = 'assets/images/onePager_leasing_shutterstock_146626955.jpg';
  targetLink = 'leasing';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Leasing vs. Mietkauf PV Anlagen und PV Speicher - BP-Center');
    this.seo.setMetaDesc('Vor- und Nachteile von Leasing, Mietkauf- und Projektfinanzierungen mit voller Flexibilität in Sachen Laufzeit, Rate, Vertragsart und Serviceleistungen.');
  }

}
