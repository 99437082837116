import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-energiedarlehen-heizung',
  templateUrl: './one-pager-energiedarlehen-heizung.component.html'
})
export class OnePagerEnergiedarlehenHeizungComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Energiedarlehen für Wärmepumpen',
    'Energiedarlehen für BHKW',
    'Energiedarlehen für Holzpellet- oder Hackschnitzelanlagen',
    'Energiedarlehen für Solartermieanlagen',
    'Energiedarlehen für Niedrigtemperaturtechnik',
    'Energiedarlehen für Brennstoffzellen und Brennwerttechnik',
    'Energiedarlehen für Infarotheizungen'
  ];
  benefitPoints = [
    'monatlicher Beitrag - Planungssicherheit',
    'freie Wahl des Produktes und des Installateurs',
    'Schonung von Liquidität, Eigenkapital',
    'Keine Grundschuldeintragung nötig',
    'schnelle Bearbeitung und Kreditzusage'
  ];
  headerTitle = 'Energiedarlehen';
  headerSubTitle = 'Die neue Heizung für Ihrer Wohnimmobilie';
  headerImgSrc = 'assets/images/onePager_contracting_shutterstock_553462765.jpg';
  preHeading = 'Modernste Heiztechnik schont Umwelt und Geldbeutel';
  heading = 'Energiedarlehen für Heizungsanlagen';
  targetLink = 'energiedarlehen';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Energiedarlehen für Heizungsanlagen - BP-Center');
    this.seo.setMetaDesc('Günstige Energiedarlehen ohne Grundschuld - schnelle Entscheidungswege, Laufzeiten bis 20 Jahre - für Angestellt, Selbständige, Rentner, Beamte und Freiberufler.');
  }

}
