import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-references',
  templateUrl: './references.component.html'
})
export class ReferencesComponent implements OnInit {

  constructor(private seo: SEOService) { }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Referenzen - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Hier finden Sie einen Auszug aus unseren Referenzen.');
  }

}
