import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-contracting-pv-speicher',
  templateUrl: './one-pager-contracting-pv-speicher.component.html'
})
export class OnePagerContractingPvSpeicherComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Contracting einer PV Anlage',
    'Contracting eines Energiespeichers'
  ];
  benefitPoints = [
    'monatlicher Beitrag - Planungssicherheit',
    'freie Wahl des Produktes und des Installateurs',
    'Schonung von Liquidität, Eigenkapital und Kreditlinie',
    '24 Stunden Service für Wartung und Instandhaltung',
    'Vollgarantie für die gesamte Laufzeit'
  ];
  headerTitle = 'PV Anlagen Contracting, Energiespeicher Contracting';
  headerSubTitle = 'Saubere Energie im Rundum -Sorglos-Paket';
  headerImgSrc = 'assets/images/onePager_contracting_pvSpeicher_shutterstock_585345260.jpg';
  preHeading = 'Saubere Energie aus Sonnenkraft schont Umwelt und Geldbeutel';
  heading = 'Contracting von PV Anlagen und Speichern';
  targetLink = 'contracting';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Contracting/Miete PV Speicher - BP-Center');
    this.seo.setMetaDesc('Wenn Sie sich für ein Contracting bzw. die Miete einer PV Anlage oder eines Energiespeichers entscheiden, blebien Sie finanziell unabhängig und erhalten ein Rund-Um-Sorglospaket. Herstellerunabhängig.');
  }

}
