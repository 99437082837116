import { MailingService, NeueAnfrageEmail } from './../../../services/mailing.service';
import { FormularService } from './../formular.service';
import { AppConfigService } from './../../../services/app.config.service';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgForm, FormControl } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';

export class Kredit {
  restschuld: number;
  rate: number;
  auslauf: string;
  abloesen: string;
  besicherung: string;
}

export class Darlehen {
  restschuld: number;
  rate: number;
  grundpfand: number;
  auslauf: string;
  abloesen: string;
}

export class Kind {
  name: string;
  geburtsdatum: string;
}

class KontaktDaten {
  tel: string;
  erreichbarkeit: string;
  email: string;
  requestCause: string;
  angebotVorhanden: string;
  ansprechpartner: string;
  ansprechpartnerEmail: string;
  ansprechpartnerTel: string;
  angebotGewuenscht: string;
}

class Eckdaten {
  was: string;
  projektstart: string;
  art: string;
  wie: string;
  allein: string;
  eigentumSeit: string;
}

class PersoenlicheDaten {
  anrede: string;
  titel: string;
  vorname: string;
  nachname: string;
  geburtsname: string;
  geburtsort: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  land: string;
  landSeit: string;
  staatsang: string;
  geburtsdatum: string;
  familienstand: string;
  gueterstand: string;
  beschaeftigung: string;
  branche: string;
  beschaeftigtBei: string;
  beruf: string;
  beschaeftigtSeit: string;
  befristet: string;
  beschaeftigtBis: string;
  kinder: Kind[] = [];
}

class EinnahmenDaten {
  lohnAnz: string;
  lohn: string;
  renten: string;
  kindergeld: string;
  unterhalt: string;
  selbst: string;
}

class AusgabenDaten {
  wohnsituation: string;
  warmmiete: string;
  versicherung: string;
  unterhalt: string;
  sonstiges: string;
}

class ImmobilienDaten {
  anzObj: string;
  kaltmietein: string;
  marktwert: string;
  restschuld: string;
  raten: string;
}

class ObjektDaten {
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  baujahr: string;
  modernisierungsjahr: string;
}

class Massnahmen {
  kosten: string;
  solar: string;
  strom: string;
  waerme: string;
  fenster: string;
  heizung: string;
  boden: string;
  raeume: string;
  bad: string;
  dach: string;
  sonstiges: string;
  sonstiges_details: string;
}

class GrundbuchDaten {
  eigentuemer: string;
  grundbuchart: string;
  grundbuchamt: string;
  grundbuchVon: string;
  grundbuchBlatt: string;
}

class AusweisDaten {
  ausweisart: string;
  ausweisnummer: string;
  ausweisBehoerde: string;
  ausweisBis: string;
}

class KontoDaten {
  iban: string;
  bank: string;
  kontoinhaber: string;
  verwendenFuerAuszahlung: string;
  sepa: string;
  sepaDa: string;
}

class LockedBy {
  _id: string;
  email: string;
  username: string;
  name: string;
}

export class LangAnfrage {
  _id: string;
  kontaktdaten: KontaktDaten = new KontaktDaten();
  eckdaten: Eckdaten = new Eckdaten();
  persoenliches = {
    person1: new PersoenlicheDaten(),
    person2: new PersoenlicheDaten()
  };
  bonitaet = {
    einnahmen: {
      person1: new EinnahmenDaten(),
      person2: new EinnahmenDaten()
    },
    ausgaben: new AusgabenDaten(),
    immobilien: new ImmobilienDaten(),
    kredite: new Array<Kredit>(),
    darlehen: new Array<Darlehen>()
  };
  vorhaben = {
    objekt: new ObjektDaten(),
    massnahmen: new Massnahmen(),
    grundbuch: new GrundbuchDaten()
  };
  legitimation = {
    ausweis: {
      person1: new AusweisDaten(),
      person2: new AusweisDaten()
    },
    konto: new KontoDaten()
  };
  anmerkungen: string;
  ursprung: string;
}

@Component({
  selector: 'bpc-langantrag-formular',
  templateUrl: './langantrag-formular.component.html',
  styleUrls: ['./langantrag-formular.component.scss']
})
export class LangantragFormularComponent implements OnInit {

  @Input() ursprung: string;

  branding: string;
  status = 'open';
  pages: { title: string, key: string }[] = [];
  currentIndex: number;
  anfrage: LangAnfrage;

  gender: {}[] | {value: string, label: string}[];
  requestType: {}[] | {value: string, label: string}[];
  propertyType: {}[] | {value: string, label: string}[];
  propertyUsage: {}[] | {value: string, label: string}[];
  bool: {}[] | {value: string, label: string}[];
  title: {}[] | {value: string, label: string}[];
  familyStatus: {}[] | {value: string, label: string}[];
  maritalProperty: {}[] | {value: string, label: string}[];
  professionalGroup: {}[] | {value: string, label: string}[];
  professionalBranch: {}[] | {value: string, label: string}[];
  housingSituation: {}[] | {value: string, label: string}[];
  collateralization: {}[] | {value: string, label: string}[];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private cs: AppConfigService, private fs: FormularService, private ms: MailingService) {
    this.branding = cs.getBranding();
    this.anfrage = new LangAnfrage();
    this.currentIndex = 0;

    this.pages.push({ title: 'Kontaktdaten', key: 'start' });
    this.pages.push({ title: 'Eckdaten', key: 'eckdaten' });
    this.pages.push({ title: 'Persönliches', key: 'pers' });
    this.pages.push({ title: 'Bonität', key: 'bonitaet' });
    this.pages.push({ title: 'Vorhaben', key: 'objekt' });
    // this.pages.push({ title: 'Legitimation', key: 'legitimation' });

    this.anfrage.bonitaet.darlehen.push(new Darlehen());
    this.anfrage.bonitaet.kredite.push(new Kredit());

    this.anfrage.eckdaten.allein = 'nein';
  }

  ngOnInit() {
    this.anfrage.ursprung = this.ursprung;
    this.gender = this.cs.getEnum('gender');
    this.requestType = this.cs.getEnum('requestType');
    this.propertyType = this.cs.getEnum('propertyType');
    this.propertyUsage = this.cs.getEnum('propertyUsage');
    this.bool = this.cs.getEnum('bool');
    this.title = this.cs.getEnum('title');
    this.familyStatus = this.cs.getEnum('familyStatus');
    this.maritalProperty = this.cs.getEnum('maritalProperty');
    this.professionalGroup = this.cs.getEnum('professionalGroup');
    this.professionalBranch = this.cs.getEnum('professionalBranch');
    this.housingSituation = this.cs.getEnum('housingSituation');
    this.collateralization = this.cs.getEnum('collateralization');
  }

  addChildToPerson1() {
    this.anfrage.persoenliches.person1.kinder.push(new Kind());
  }

  removeChildFromPerson1() {
    this.anfrage.persoenliches.person1.kinder.splice(-1);
  }

  addChildToPerson2() {
    this.anfrage.persoenliches.person2.kinder.push(new Kind());
  }

  removeChildFromPerson2() {
    this.anfrage.persoenliches.person2.kinder.splice(-1);
  }

  addNewLoan() {
    this.anfrage.bonitaet.darlehen.push(new Darlehen());
  }

  dropLoan(index: number) {
    this.anfrage.bonitaet.darlehen.splice(index, 1);
  }

  addNewCredit() {
    this.anfrage.bonitaet.kredite.push(new Kredit());
  }

  dropCredit(index: number) {
    this.anfrage.bonitaet.kredite.splice(index, 1);
  }

  onSubmit() {
    this.status = 'pending';
    this.fs.sendLongData('long', this.branding, this.anfrage).toPromise().then((resp) => {
      return this.ms.confirmNewInquiryToCustomer(
        new NeueAnfrageEmail(
          this.anfrage.persoenliches.person1.nachname, this.anfrage.kontaktdaten.email,
          this.anfrage.persoenliches.person1.anrede, this.branding
        )
      ).toPromise();
    }).then(() => {
      return this.ms.newInquiryInternal(this.ursprung).toPromise();
    }).then(() => {
      this.status = 'sent';
    }).catch((error) => {
      this.status = 'error';
    });
  }

  isKeyActive(key: string) {
    return this.pages[this.currentIndex].key === key;
  }
  isKeyDone(key: string) {
    let keyIndex = 0;
    for (let i = 0; i < this.pages.length; i++) {
      if (this.pages[i].key === key) {
        keyIndex = i;
      }
    }
    return keyIndex < this.currentIndex;
  }
  setIndexByKey(key: string) {
    for (let i = 0; i < this.pages.length; i++) {
      if (this.pages[i].key === key) {
        this.currentIndex = i;
      }
    }
    this.scrollToProgressBar();
  }

  isPreviousPageAvailable() {
    return this.currentIndex > 0;
  }

  previousPage() {
    this.currentIndex = this.currentIndex - 1;
    this.scrollToProgressBar();
  }

  isCurrentPageValid(form: NgForm) {
    if (form.controls['group' + (this.currentIndex + 1)]) {
      return form.controls['group' + (this.currentIndex + 1)].valid;
    }
    return false;
  }

  isNextPageAvailable() {
    return this.currentIndex < (this.pages.length - 1);
  }

  nextPage() {
    this.currentIndex = this.currentIndex + 1;
    this.scrollToProgressBar();
  }

  isSecondPersonSelected() {
    return this.anfrage.eckdaten.allein === 'ja';
  }

  onModalClose(modal: ModalDirective) {
    modal.hide();
    this.onSubmit();
  }

  findPos(obj: any) {
    let curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
      return curtop;
    }
  }
  scrollToProgressBar() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, Math.max(0, this.findPos(document.getElementById('progressbar')) - 140));
    }
  }

}
