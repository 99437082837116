import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-forwarddarlehen',
  templateUrl: './one-pager-forwarddarlehen.component.html'
})
export class OnePagerForwarddarlehenComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Forward-Darlehen für selbst genutzte Immobilien',
    'Forward-Darlehen für vermietete Immobilien',
    'Kapitalanlagefinanzierungen',
    'Forward-Darlehen in Verbindung mit Modernisierungen'
  ];
  benefitPoints = [
    'umfassende und neutrale Beratung',
    'freie Wahl des Produktes und des Finanzierungspartners',
    'Sonderprodukte unserer Finanzierungspartner',
    'Begleitung bis zur vollständigen Auszahlung des Darlehens',
    'Beratung über Sonderaktionen'
  ];
  preHeading = 'Jetzt schon die Zinsen von morgen sichern';
  heading = 'Forward-Darlehen so funktioniert ́s';
  headerTitle = 'Forward-Darlehen';
  headerSubTitle = 'Immobilienfinanzierung mit grundschuldrechtlicher Besicherung';
  headerImgSrc = 'assets/images/onePager_forwarddarlehen_shutterstock_687170326.jpg';
  targetLink = 'forwarddarlehen';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Forwrarddarlehen - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Jetzt niedrige Zinsen für Ihre Anschlussfinanzierung sichern. Bis 60 Monate vor Zinsbindungsende.');
  }

}
