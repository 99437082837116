import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

type TeslaSliderData = {laufzeit: number, werte: {summe: number, rate: number, rest: number}[]}[]

@Injectable()
export class AppConfigService {

  private backendUrl: string = 'http://localhost:3000';
  private frontendUrl: string = 'http://localhost:4201';
  private enums = {};
  private platforms = [];
  private defaultBranding = { key: 'bpc', desc: 'BPC' };
  private branding = { key: 'bpc', desc: 'BPC' };

  public appTitle = 'Bau- & Projektfinanzierungs-Center';

  private BACKENDURL_KEY = makeStateKey<string>('BACKEND_URL');
  private FRONTENDURL_KEY = makeStateKey<string>('FRONTEND_URL');
  private ENUMS_KEY = makeStateKey<string>('ENUMS');
  private PLATFORMS_KEY = makeStateKey<string>('PLATFORMS');

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState,
    @Optional() @Inject('backendUrl') protected backendUrlInject: string,
    @Optional() @Inject('frontendUrl') protected frontendUrlInject: string,
  ) {
    if (isPlatformServer(this.platformId) && backendUrlInject) {
      this.transferState.set(this.BACKENDURL_KEY, backendUrlInject);
    }
    if (isPlatformServer(this.platformId) && frontendUrlInject) {
      this.transferState.set(this.FRONTENDURL_KEY, frontendUrlInject);
    }
  }

  getSliderData(laufzeit: number) {
    const SLIDER_KEY = makeStateKey<string>('SLIDER_' + laufzeit);
    if (this.transferState.hasKey(SLIDER_KEY)) {
      const sliderValues = this.transferState.get(SLIDER_KEY, null);
      return of(sliderValues);
    } else {
      return this.http.get(this.backendUrl + '/api/configuration/sliderData/' + laufzeit).pipe(
        tap(sliderValues => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(SLIDER_KEY, sliderValues);
          }
        })
      );
    }
  }

  getTeslaSliderData(): Observable<TeslaSliderData> {
    const SLIDER_KEY = makeStateKey<string>('TESLA_SLIDER');
    if (this.transferState.hasKey(SLIDER_KEY)) {
      const sliderValues = this.transferState.get(SLIDER_KEY, null);
      return of(sliderValues);
    } else {
      return this.http.get<TeslaSliderData>(this.backendUrl + '/api/configuration/teslaSliderData').pipe(
        tap(sliderValues => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(SLIDER_KEY, sliderValues);
          }
        })
      );
    }
  }

  getBackendUrl(): string {
    return this.backendUrl;
  }

  getFrontendUrl(): string {
    return this.frontendUrl;
  }

  isPlatformExisting(platform: string) {
    for (const pf of this.platforms) {
      if (pf.key === platform) {
        this.branding = pf;
        return true;
      }
    }
    this.branding = this.defaultBranding;
    return false;
  }

  getBranding() {
    return this.branding.key;
  }

  getEnum(enumKey: string) {
    if (this.enums.hasOwnProperty(enumKey)) {
      return Array.from(this.enums[enumKey]);
    }
    return [{ value: enumKey, label: enumKey }];
  }

  getKeyForEnumVal(enumKey: string, val: string) {
    if (this.enums.hasOwnProperty(enumKey)) {
      for (const enumItem of this.enums[enumKey]) {
        if (enumItem.val === val) {
          return enumItem.key;
        }
      }
    }
    return val;
  }

  getValForEnumKey(enumKey: string, key: string) {
    if (this.enums.hasOwnProperty(enumKey)) {
      for (const enumItem of this.enums[enumKey]) {
        if (enumItem.key === key) {
          return enumItem.val;
        }
      }
    }
    return key;
  }

  loadBackendUrl() {
    if (this.transferState.hasKey(this.BACKENDURL_KEY)) {
      const backendUrl = this.transferState.get<string>(this.BACKENDURL_KEY, null);
      return of({ url: backendUrl });
    } else {
      if (!isPlatformServer(this.platformId)) {
        return this.http.get<{ url: string }>(window.location.origin + '/backend').pipe(
          catchError(_err => of({ url: 'http://localhost:3000' }))
        );
      } else {
        return of({ url: this.backendUrl })
      }
    }
  }

  loadFrontendUrl() {
    if (this.transferState.hasKey(this.FRONTENDURL_KEY)) {
      const frontendUrl = this.transferState.get<string>(this.FRONTENDURL_KEY, null);
      return of({ url: frontendUrl });
    } else {
      if (!isPlatformServer(this.platformId)) {
        return this.http.get<{ url: string }>(window.location.origin + '/frontend').pipe(
          catchError(_err => of({ url: 'http://localhost:4201' }))
        );
      } else {
        return of({ url: this.frontendUrl })
      }
    }
  }

  loadPlatforms() {
    if (this.transferState.hasKey(this.PLATFORMS_KEY)) {
      const platforms = this.transferState.get<any[]>(this.PLATFORMS_KEY, null);
      return of(platforms);
    } else {
      return this.http.get<any[]>(this.backendUrl + '/api/configuration/platform').pipe(
        catchError(_err => of([]))
      );
    }
  }

  loadEnums() {
    if (this.transferState.hasKey(this.ENUMS_KEY)) {
      const enums = this.transferState.get(this.ENUMS_KEY, null);
      return of(enums);
    } else {
      return this.http.get(this.backendUrl + '/api/configuration/enums').pipe(
        catchError(_err => of({}))
      );
    }
  }

  public load() {

    return new Promise((resolve, _reject) => {

      this.loadBackendUrl().toPromise().then((urlBackend) => {
        this.backendUrl = urlBackend.url;

        return this.loadFrontendUrl().toPromise();
      }).then((urlFrontend) => {
        this.frontendUrl = urlFrontend.url;

        return this.loadPlatforms().toPromise();
      }).then((platforms) => {
        this.platforms = platforms;

        if (isPlatformServer(this.platformId)) {
          this.transferState.set(this.PLATFORMS_KEY, this.platforms);
        }

        return this.loadEnums().toPromise();
      }).then((enums) => {
        this.enums = enums;

        if (isPlatformServer(this.platformId)) {
          this.transferState.set(this.ENUMS_KEY, this.enums);
        }

        resolve();
      }).catch((err) => {
        console.log('Fehler beim Laden der notwendigen Backend-Daten', err);
        resolve();
      });
    });

  }
}
