import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-gewerbe-finanzierung',
  templateUrl: './gewerbe-finanzierung.component.html',
  styleUrls: ['./gewerbe-finanzierung.component.scss']
})
export class GewerbeFinanzierungComponent implements OnInit {

  branding: string;

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('gewerbliche Finanzierung - BP-Center');
    this.seo.setMetaDesc('Gewerbliche Finanzierungen, Bauträgerfinanzierungen, Investitionen in Immobilien oder technologische Modernisierung sowie Kreditlinien.');
  }

}
