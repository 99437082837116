import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bpc-header-view',
  templateUrl: './header-view.component.html',
  styleUrls: ['./header-view.component.scss']
})
export class HeaderViewComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() imgSrc = '';

  constructor() { }

  ngOnInit() {
  }

}
