import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from './../../services/app.config.service';
import { KontaktAnfrage } from './kontakt-formular/kontakt-formular.component';
import { KurzAnfrage } from './kurzanfrage-formular/kurzanfrage-formular.component';
import { LangAnfrage } from './langantrag-formular/langantrag-formular.component';
import { LeasingAnfrage } from './leasing-formular/leasing-formular.component';
import { ProjektAnfrage } from './projekte-formular/projekte-formular.component';
import { GewerbeAnfrage } from './gewerbe-formular/gewerbe-formular.component';
import { ContractingAnfrage } from './contracting-formular/contracting-formular.component';

@Injectable({
  providedIn: 'root'
})
export class FormularService {

  constructor(private http: HttpClient, private cs: AppConfigService) { }

  public sendContactData(type: string, branding: string, anfrage: KontaktAnfrage) {
    console.log('sende Kontaktanfrage: ', anfrage);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/contact';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendShortData(type: string, branding: string, anfrage: KurzAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/short';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendLongData(type: string, branding: string, anfrage: LangAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/long';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendLeasingAnfrage(type: string, branding: string, anfrage: LeasingAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/leasing';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendProjektAnfrage(type: string, branding: string, anfrage: ProjektAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/project';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendGewerbeAnfrage(type: string, branding: string, anfrage: GewerbeAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/industry';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendBusinesskreditAnfrage(branding: string, anfrage: GewerbeAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/business';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

  sendContractingAnfrage(type: string, branding: string, anfrage: ContractingAnfrage) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.cs.getBackendUrl() + '/api/inquiry/contracting';

    return this.http.post(url, { branding, formData: anfrage }, { headers: headers });
  }

}
