import { AppConfigService } from "./../../../services/app.config.service";
import { MailingService } from "./../../../services/mailing.service";
import { Component, OnInit } from "@angular/core";
import { FormularService } from "../formular.service";
import { ModalDirective } from "ng-uikit-pro-standard";

export class GewerbeAnfrage {
  name: string;
  rechtsform: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  neugruendung: string;
  unternehmenSeit: string;
  vertretenDurch: string;
  geb: string;
  vorhaben: string;
  volumen: number;
  erreichbarkeit: string;
  tel: string;
  email: string;
}

@Component({
  selector: "bpc-gewerbe-formular",
  templateUrl: "./gewerbe-formular.component.html"
})
export class GewerbeFormularComponent implements OnInit {
  anfrage: GewerbeAnfrage;
  branding: string;
  status = "open";

  constructor(
    private cs: AppConfigService,
    private fs: FormularService,
    private ms: MailingService
  ) {
    this.anfrage = new GewerbeAnfrage();
    this.branding = cs.getBranding();
  }

  ngOnInit() {}

  onSubmit() {
    this.status = "pending";
    this.fs
      .sendGewerbeAnfrage("projekte", this.branding, this.anfrage)
      .toPromise()
      .then(resp => {
        return this.ms.newInquiryInternal("Gewerbliche Finanzierung").toPromise();
      })
      .then(() => {
        this.status = "sent";
      })
      .catch(error => {
        this.status = "error";
      });
  }

  onModalClose(modal: ModalDirective) {
    modal.hide();
    this.onSubmit();
  }
}
