import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-modernisierung',
  templateUrl: './one-pager-modernisierung.component.html'
})
export class OnePagerModernisierungComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Modernisierungsdarlehen für Um- und Ausbau',
    'Modernisierungsdarlehen für BHWKW und Wärmepumpen',
    'Modernisierungsdarlehen für PV-Anlagen und Energiespeicher',
    'Modernisierungsdarlehen für Sauna, Pool und Schwimmbad',
    'Modernisierungsdarlehen für Einbauküchen',
    'Modernisierungsdarlehen für Brennstoffzellen und Brennwerttechnik',
    'Modernisierungsdarlehen für Infarotheizungen'
  ];
  benefitPoints = [
    'monatlicher Beitrag - Planungssicherheit',
    'freie Wahl des Produktes und des Installateurs',
    'Schonung von Liquidität, Eigenkapital',
    'Keine Grundschuldeintragung nötig',
    'schnelle Bearbeitung und Kreditzusage'
  ];
  preHeading = 'Werte erhalten und Energie sparen';
  heading = 'Modernisierungskredit';
  headerTitle = 'Modernisierungsdarlehen ohne Grundschuld';
  headerSubTitle = 'Blankodarlehen';
  headerImgSrc = 'assets/images/Modernisierungsdarlehen_749271490.jpg';
  targetLink = 'modernisierung';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Modernisierung - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Günstige Modernisierungsdarlehen ohne Grundschuld - schnelle Entscheidungswege, Laufzeiten bis 20 Jahre - für Angestellt, Selbständige, Rentner, Beamte und Freiberufler.');
  }

}
