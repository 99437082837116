import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { SEOService } from '../../../services/seo.service';

@Component({
  selector: 'bpc-modernisierung',
  templateUrl: './modernisierung.component.html',
  styleUrls: ['./modernisierung.component.scss']
})
export class ModernisierungComponent implements OnInit {

  branding: string;
  laufzeit = "20";

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Modernisierung - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Günstige Modernisierungsdarlehen ohne Grundschuld  - schnelle Entscheidungswege, Laufzeiten bis 20 Jahre - für Angestellt, Selbständige, Rentner, Beamte und Freiberufler.');
  }

}
