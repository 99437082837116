import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-businesskredit',
  templateUrl: './businesskredit.component.html',
  styleUrls: ['./businesskredit.component.scss']
})
export class BusinesskreditComponent implements OnInit {

  branding: string;

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Businesskredit z. freien Verwendung - BP-Center ');
    this.seo.setMetaDesc('Liquidität für Ihr Unternehmen schaffen. Bis 100,0 T€ zur freien Verwendung, geschäftlich oder privat, ohne Sicherheiten, mit kurzen Entscheidungswegen.');
  }

}
