import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-led',
  templateUrl: './one-pager-led.component.html'
})
export class OnePagerLedComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Leasing von LED Technik',
    'Mietkauf von LED Technik',
    'Darlehen für LED Technik',
    'Projektfinanzierung von LED Technik',
    'Absatzfinanzierung von LED Technik'
  ];
  benefitPoints = [
    'sofortiger Kostenvorteil',
    'sichere Kalkulationsbasis',
    'Schonung von Liquidität, Eigenkapital und Kreditlinie',
    'Individuelle Vertragsgestaltung',
    'Herstellerunabhängige Finanzierung'
  ];
  preHeading = 'Kosten senken auf Knopfdruck';
  heading = 'LED-Technik finanzieren';
  headerTitle = 'LED-Technik finanzieren';
  headerImgSrc = 'assets/images/onePager_led_shutterstock_1020531577.jpg';
  targetLink = 'projektfinanzierung';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('LED Finanzierung - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Kostensenken auf Knopfdruck - mit LED Technik senken Sie Ihre Kosten - durch die herstellerunabhängige Finanzierung verbessern Sie Ihre Liquidität.');
  }

}
