import { BusinesskreditFormularComponent } from './formulare/businesskredit-formular/businesskredit-formular.component';
import { BusinesskreditComponent } from './pages/businesskredit/businesskredit.component';
import { DatenschutzerklaerungComponent } from './pages/datenschutzerklaerung/datenschutzerklaerung.component';
import { ContractingFormularComponent } from './formulare/contracting-formular/contracting-formular.component';
import { LeasingFormularComponent } from './formulare/leasing-formular/leasing-formular.component';
import { OnePagerModernisierungComponent } from './onePager/one-pager-modernisierung/one-pager-modernisierung.component';
import { OnePagerLeasingComponent } from './onePager/one-pager-leasing/one-pager-leasing.component';
import { OnePagerForwarddarlehenComponent } from './onePager/one-pager-forwarddarlehen/one-pager-forwarddarlehen.component';
import { OnePagerFinanzierungPvSpeicherComponent } from './onePager/one-pager-finanzierung-pv-speicher/one-pager-finanzierung-pv-speicher.component';
import { OnePagerEnergiedarlehenHeizungComponent } from './onePager/one-pager-energiedarlehen-heizung/one-pager-energiedarlehen-heizung.component';
import { OnePagerContractingPvSpeicherComponent } from './onePager/one-pager-contracting-pv-speicher/one-pager-contracting-pv-speicher.component';
import { OnePagerEnergiedarlehnenPvSpeicherComponent } from './onePager/one-pager-energiedarlehnen-pv-speicher/one-pager-energiedarlehnen-pv-speicher.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { FooterComponent } from '../footer/footer.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { ContentComponent } from './content.component';
import { ContentRoutingModule } from './content-routing.module';
import { CardComponent } from './components/card/card.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { HeaderViewComponent } from './components/header-view/header-view.component';
import { StartComponent } from './pages/start/start.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { ModernisierungComponent } from './pages/modernisierung/modernisierung.component';
import { LeasingComponent } from './pages/leasing/leasing.component';
import { ProjektfinanzierungComponent } from './pages/projektfinanzierung/projektfinanzierung.component';
import { BaufinanzierungComponent } from './pages/baufinanzierung/baufinanzierung.component';
import { GewerbeFinanzierungComponent } from './pages/gewerbe-finanzierung/gewerbe-finanzierung.component';
import { ForwarddarlehenComponent } from './pages/forwarddarlehen/forwarddarlehen.component';
import { ContractingComponent } from './pages/contracting/contracting.component';
import { EnergiedarlehenComponent } from './pages/energiedarlehen/energiedarlehen.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { KontaktFormularComponent } from './formulare/kontakt-formular/kontakt-formular.component';
import { FormularService } from './formulare/formular.service';
import { RequestStatusComponent } from './components/request-status/request-status.component';
import { OnePagerBaufinanzierungComponent } from './onePager/one-pager-baufinanzierung/one-pager-baufinanzierung.component';
import { OnePagerTemplateComponent } from './onePager/one-pager-template/one-pager-template.component';
import { SliderComponent } from './components/slider/slider.component';
import { OnePagerContractingHeizungComponent } from './onePager/one-pager-contracting-heizung/one-pager-contracting-heizung.component';
import { OnePagerMietkaufComponent } from './onePager/one-pager-mietkauf/one-pager-mietkauf.component';
import { KurzanfrageFormularComponent } from './formulare/kurzanfrage-formular/kurzanfrage-formular.component';
import { LangantragFormularComponent } from './formulare/langantrag-formular/langantrag-formular.component';
import { DatenschutzContentComponent } from './pages/datenschutz-content/datenschutz-content.component';
import { SchufaContentComponent } from './pages/schufa-content/schufa-content.component';
import { ProjekteFormularComponent } from './formulare/projekte-formular/projekte-formular.component';
import { GewerbeFormularComponent } from './formulare/gewerbe-formular/gewerbe-formular.component';
import { OnePagerLeasingVsMietkaufComponent } from './onePager/one-pager-leasingVsMietkauf/one-pager-leasingVsMietkauf.component';
import { OnePagerLedComponent } from './onePager/one-pager-led/one-pager-led.component';
import { TeslaLeasingComponent } from './pages/tesla-leasing/tesla-leasing.component';
import { TeslaSliderComponent } from './components/tesla-slider/tesla-slider.component';
import { ReferencesComponent } from './pages/references/references.component';

@NgModule({
  imports: [
    CommonModule,
    ContentRoutingModule,
    FormsModule,
    MDBBootstrapModulesPro,
    AgmCoreModule
  ],
  declarations: [
    StartComponent,
    ImpressumComponent,
    ContentComponent,
    CardComponent,
    TestimonialComponent,
    ModernisierungComponent,
    LeasingComponent,
    ProjektfinanzierungComponent,
    BaufinanzierungComponent,
    GewerbeFinanzierungComponent,
    ForwarddarlehenComponent,
    ContractingComponent,
    HeaderViewComponent,
    EnergiedarlehenComponent,
    KontaktComponent,
    DatenschutzComponent,
    KontaktFormularComponent,
    NavbarComponent,
    FooterComponent,
    RequestStatusComponent,
    SliderComponent,
    TeslaSliderComponent,
    OnePagerTemplateComponent,
    OnePagerBaufinanzierungComponent,
    OnePagerEnergiedarlehenHeizungComponent,
    OnePagerEnergiedarlehnenPvSpeicherComponent,
    OnePagerContractingHeizungComponent,
    OnePagerContractingPvSpeicherComponent,
    OnePagerFinanzierungPvSpeicherComponent,
    OnePagerForwarddarlehenComponent,
    OnePagerLeasingComponent,
    OnePagerMietkaufComponent,
    OnePagerModernisierungComponent,
    KurzanfrageFormularComponent,
    LangantragFormularComponent,
    DatenschutzContentComponent,
    SchufaContentComponent,
    LeasingFormularComponent,
    ProjekteFormularComponent,
    GewerbeFormularComponent,
    ContractingFormularComponent,
    OnePagerLeasingVsMietkaufComponent,
    OnePagerLedComponent,
    DatenschutzerklaerungComponent,
    BusinesskreditComponent,
    BusinesskreditFormularComponent,
    TeslaLeasingComponent,
    ReferencesComponent
  ],
  providers: [
    FormularService
  ]
})
export class ContentModule { }
