import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../services/app.config.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'bpc-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit {

  branding = 'bpc';

  constructor(private cs: AppConfigService, private route: ActivatedRoute, private router: Router) {
    if (!cs.isPlatformExisting(route.snapshot.params.branding)) {
      router.navigate(['/bpc']);
    } else {
      this.branding = cs.getBranding();
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (!this.cs.isPlatformExisting(params['branding'])) {
        this.router.navigate(['/bpc']);
      } else {
        this.branding = this.cs.getBranding();
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
