import { Component } from '@angular/core';
import { AppConfigService } from '../services/app.config.service';

@Component({
  selector: 'bpc-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  branding: string;

  constructor(cs: AppConfigService) {
    this.branding = cs.getBranding();
  }

}
