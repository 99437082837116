import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { SEOService } from '../../../services/seo.service';

@Component({
  selector: 'bpc-tesla-leasing',
  templateUrl: './tesla-leasing.component.html',
  styleUrls: ['./tesla-leasing.component.scss']
})
export class TeslaLeasingComponent implements OnInit {

  branding: string;
  laufzeit = "20";

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Elektromobilität / PKW Leasing - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Wir bieten Ihnen eine herstellerunabhängige Finanzierung für alle PKW, einschließlich Elektroauto, an. Egal ob Smart oder Tesla, bei uns finden Sie garantiert die günstigste Rate mit einem fairen Rest- bzw. Andienungswert.');
  }

}
