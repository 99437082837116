import { AppConfigService } from './../../../services/app.config.service';
import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-baufinanzierung',
  templateUrl: './one-pager-baufinanzierung.component.html'
})
export class OnePagerBaufinanzierungComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Baufinanzierung für Um- und Ausbau',
    'Baufinanzierung für Neubau von Immobilien',
    'Baufinanzierung für Kauf von Immobilien',
    'Baufinanzierung für Anschlussfinanzierung oder Umschuldung',
    'Forward-Darlehen',
    'Kapitalanlagefinanzierungen'
  ];
  benefitPoints = [
    'umfassende und neutrale Beratung',
    'freie Wahl des Produktes und des Finanzierungspartners',
    'Sonderprodukte unserer Finanzierungspartner',
    'Begleitung bis zur vollständigen Auszahlung des Darlehens',
    'Beratung über Fördermöglichkeiten und Sonderaktionen'
  ];
  preHeading = 'Die optimale Baufinanzierung benötigt einen professionellen Partner';
  heading = 'Wunschfinanzierung für Ihr Bauvorhaben';
  headerTitle = 'Baufinanzierung';
  headerSubTitle = 'Immobilienfinanzierung mit grundschuldrechtlicher Besicherung';
  // tslint:disable-next-line:max-line-length
  headerImgSrc = 'assets/images/onePager_baufinanzierung_shutterstock_278455016.jpg';
  targetLink = 'baufinanzierung';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Baufinanzierung - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Neubau, Kauf, Sanierung - Anbietervergleich ermöglicht beste Zinsen für Ihre individuelle Finanzierung. Sonderaktionen und Förderungen sowie regionale Besonderheiten berücksichtigen wir selbstverständlich.');
  }

}
