import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-energiedarlehnen-pv-speicher',
  templateUrl: './one-pager-energiedarlehnen-pv-speicher.component.html'
})
export class OnePagerEnergiedarlehnenPvSpeicherComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Energiedarlehen für Finanzierung der PV Anlage',
    'Energiedarlehen für die Finanzierung von Energiespeichern'
  ];
  benefitPoints = [
    'monatlicher Beitrag - Planungssicherheit',
    'freie Wahl des Produktes und des Installateurs',
    'Schonung von Liquidität, Eigenkapital',
    'Keine Grundschuldeintragung nötig',
    'schnelle Bearbeitung und Kreditzusage'
  ];
  headerTitle = 'Energiedarlehen';
  headerSubTitle = 'Saubere Energie in Ihrer Wohnimmobilie';
  headerImgSrc = 'assets/images/onePager_contracting_pvSpeicher_shutterstock_585345260.jpg';
  preHeading = 'Saubere Energie aus Sonnenkraft schont Umwelt und Geldbeutel';
  heading = 'Finanzierung von PV Anlagen und Speichern';
  targetLink = 'energiedarlehen';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Darlehen für Energiespeicher und PV Anlagen - BP-Center');
    this.seo.setMetaDesc('Günstige Energiedarlehen ohne Grundschuld - schnelle Entscheidungswege, Laufzeiten bis 20 Jahre - für Angestellt, Selbständige, Rentner, Beamte und Freiberufler.');
  }

}
