import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

class LeasingAnfrage {
  name: string;
  rechtsform: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  unternehmenSeit: string;
  vertretenDurch: string;
  geb: string;
  kaufpreis: number;
  vertragslaufzeit: string;
}

@Component({
  selector: 'bpc-leasing',
  templateUrl: './leasing.component.html',
  styleUrls: ['./leasing.component.scss']
})
export class LeasingComponent implements OnInit {

  anfrage: LeasingAnfrage;
  branding: string;

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.anfrage = new LeasingAnfrage();
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Leasing - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Leasing, Mietkauf- und Projektfinanzierungen mit voller Flexibilität in Sachen Laufzeit, Rate, Vertragsart und Serviceleistungen.');
  }

  onSubmit() {
    console.log(this.anfrage);
  }

}
