import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-contracting',
  templateUrl: './contracting.component.html',
  styleUrls: ['./contracting.component.scss']
})
export class ContractingComponent implements OnInit {

  branding: string;

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Contracting/Miete PV Anlagen, PV Speicher - BP- Center');
    this.seo.setMetaDesc('Wenn Sie sich für ein Contracting bzw. die Miete einer  PV Anlage, eines Energiespeichers oder einer Heizungsanlage entscheiden, bleiben Sie finanziell unabhängig und erhalten ein Rund-Um-Sorglospaket. Herstellerunabhängig.');
  }

}
