import { BusinesskreditComponent } from './pages/businesskredit/businesskredit.component';
import { DatenschutzerklaerungComponent } from './pages/datenschutzerklaerung/datenschutzerklaerung.component';
import { OnePagerModernisierungComponent } from './onePager/one-pager-modernisierung/one-pager-modernisierung.component';
import { OnePagerLeasingComponent } from './onePager/one-pager-leasing/one-pager-leasing.component';
import { OnePagerForwarddarlehenComponent } from './onePager/one-pager-forwarddarlehen/one-pager-forwarddarlehen.component';
import { OnePagerFinanzierungPvSpeicherComponent } from './onePager/one-pager-finanzierung-pv-speicher/one-pager-finanzierung-pv-speicher.component';
import { OnePagerEnergiedarlehenHeizungComponent } from './onePager/one-pager-energiedarlehen-heizung/one-pager-energiedarlehen-heizung.component';
import { OnePagerContractingPvSpeicherComponent } from './onePager/one-pager-contracting-pv-speicher/one-pager-contracting-pv-speicher.component';
import { OnePagerBaufinanzierungComponent } from './onePager/one-pager-baufinanzierung/one-pager-baufinanzierung.component';
import { ContentComponent } from './content.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { ContractingComponent } from './pages/contracting/contracting.component';
import { ForwarddarlehenComponent } from './pages/forwarddarlehen/forwarddarlehen.component';
import { GewerbeFinanzierungComponent } from './pages/gewerbe-finanzierung/gewerbe-finanzierung.component';
import { BaufinanzierungComponent } from './pages/baufinanzierung/baufinanzierung.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { StartComponent } from './pages/start/start.component';
import { ModernisierungComponent } from './pages/modernisierung/modernisierung.component';
import { LeasingComponent } from './pages/leasing/leasing.component';
import { ProjektfinanzierungComponent } from './pages/projektfinanzierung/projektfinanzierung.component';
import { EnergiedarlehenComponent } from './pages/energiedarlehen/energiedarlehen.component';
import { OnePagerEnergiedarlehnenPvSpeicherComponent } from './onePager/one-pager-energiedarlehnen-pv-speicher/one-pager-energiedarlehnen-pv-speicher.component';
import { OnePagerContractingHeizungComponent } from './onePager/one-pager-contracting-heizung/one-pager-contracting-heizung.component';
import { OnePagerMietkaufComponent } from './onePager/one-pager-mietkauf/one-pager-mietkauf.component';
import { OnePagerLeasingVsMietkaufComponent } from './onePager/one-pager-leasingVsMietkauf/one-pager-leasingVsMietkauf.component';
import { OnePagerLedComponent } from './onePager/one-pager-led/one-pager-led.component';
import { TeslaLeasingComponent } from './pages/tesla-leasing/tesla-leasing.component';
import { ReferencesComponent } from './pages/references/references.component';

const routes: Routes = [
  {
    path: ':branding',
    component: ContentComponent,
    children: [
      {
        path: 'impressum',
        component: ImpressumComponent
      },
      {
        path: 'kontakt',
        component: KontaktComponent
      },
      {
        path: 'datenschutz',
        component: DatenschutzComponent
      }, {
        path: 'datenschutzerklaerung',
        component: DatenschutzerklaerungComponent
      },
      {
        path: 'modernisierung',
        component: ModernisierungComponent
      },
      {
        path: 'projektfinanzierung',
        component: ProjektfinanzierungComponent
      },
      {
        path: 'leasing',
        component: LeasingComponent
      },
      {
        path: 'elektromobilitaet',
        component: TeslaLeasingComponent
      },
      {
        path: 'tesla-leasing',
        redirectTo: 'elektromobilitaet'
      },
      {
        path: 'baufinanzierung',
        component: BaufinanzierungComponent
      },
      {
        path: 'gewerblicheFinanzierungen',
        component: GewerbeFinanzierungComponent
      },
      {
        path: 'forwarddarlehen',
        component: ForwarddarlehenComponent
      },
      {
        path: 'contracting',
        component: ContractingComponent
      },
      {
        path: 'energiedarlehen',
        component: EnergiedarlehenComponent
      },
      {
        path: 'businesskredit',
        component: BusinesskreditComponent
      },
      {
        path: 'infos/baufinanzierung',
        component: OnePagerBaufinanzierungComponent
      },
      {
        path: 'infos/energiedarlehenHeizung',
        component: OnePagerEnergiedarlehenHeizungComponent
      },
      {
        path: 'infos/energiedarlehenPvSpeicher',
        component: OnePagerEnergiedarlehnenPvSpeicherComponent
      },
      {
        path: 'infos/contractingHeizung',
        component: OnePagerContractingHeizungComponent
      },
      {
        path: 'infos/contractingPvSpeicher',
        component: OnePagerContractingPvSpeicherComponent
      },
      {
        path: 'infos/finanzierungPvSpeicher',
        component: OnePagerFinanzierungPvSpeicherComponent
      },
      {
        path: 'infos/forwarddarlehen',
        component: OnePagerForwarddarlehenComponent
      },
      {
        path: 'infos/leasing',
        component: OnePagerLeasingComponent
      },
      {
        path: 'infos/mietkauf',
        component: OnePagerMietkaufComponent
      },
      {
        path: 'infos/modernisierung',
        component: OnePagerModernisierungComponent
      },
      {
        path: 'infos/leasingOderMietkauf',
        component: OnePagerLeasingVsMietkaufComponent
      },
      {
        path: 'infos/LEDFinanzierung',
        component: OnePagerLedComponent
      },
      {
        path: 'referenzen',
        component: ReferencesComponent
      },
      {
        path: '',
        component: StartComponent,
        pathMatch: 'full'
      },
      { path: '**', redirectTo: '' }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ContentRoutingModule { }
