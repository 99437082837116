import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from './app.config.service';

export class BearbeitungsStartMailAnsprechpartner {
  empfaengerEmail: string;
  kundenname: string;

  constructor(mail: string, kundenname: string) {
    this.empfaengerEmail = mail;
    this.kundenname = kundenname;
  }
}

export class BearbeitungsStartMail {
  empfaengerEmail: string;
  empfaengerName: string;
  empfaengerGeschlecht: string;

  constructor(mail: string, name: string, geschlecht: string) {
    this.empfaengerEmail = mail;
    this.empfaengerName = name;
    this.empfaengerGeschlecht = geschlecht;
  }
}

export class NeueAnfrageEmail {
  name: string;
  email: string;
  geschlecht: string;
  branding: string;

  constructor(name: string, email: string, geschlecht: string, branding: string) {
    this.name = name;
    this.email = email;
    this.geschlecht = geschlecht;
    this.branding = branding;
  }
}


@Injectable()
export class MailingService {
  constructor(private http: HttpClient, private cs: AppConfigService) { }

  newInquiryInternal(requestType: string) {
    const url = this.cs.getBackendUrl() + '/api/mailing/newInquiryInternal';
    return this.http.post(url, { requestType });

  }

  confirmNewInquiryToCustomer(mailContent: NeueAnfrageEmail) {
    const url = this.cs.getBackendUrl() + '/api/mailing/confirmNewInquiryToCustomer';
    return this.http.post(url, { mailContent });
  }
}
