import { AppConfigService } from './../../../services/app.config.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bpc-card',
  templateUrl: './card.component.html'
})
export class CardComponent implements OnInit {
  @Input() title = 'orig';
  @Input() text = [''];
  @Input() link = '';
  @Input() imgSrc = '';
  branding: string;

  constructor(private cs: AppConfigService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
  }

}
