import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-finanzierung-pv-speicher',
  templateUrl: './one-pager-finanzierung-pv-speicher.component.html'
})
export class OnePagerFinanzierungPvSpeicherComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Finanzierung PV Anlage',
    'Finanzierung Energiespeichers'
  ];
  benefitPoints = [
    'monatlicher Beitrag - Planungssicherheit',
    'freie Wahl des Produktes und des Installateurs',
    'Schonung von Liquidität, Eigenkapital',
    'Keine Grundschuldeintragung nötig',
    'schnelle Bearbeitung und Kreditzusage'
  ];
  preHeading = 'Saubere Energie in Ihrer Wohnimmobilie';
  heading = 'PV Anlagen Finanzierung, Energiespeicher Finanzierung';
  headerTitle = 'Finanzierung von PV Anlagen und Speichern';
  headerSubTitle = 'Saubere Energie aus Sonnenkraft schont Umwelt und Geldbeutel';
  headerImgSrc = 'assets/images/finanzierungPVAnlage_shutterstock_485556736.jpg';
  targetLink = 'energiedarlehen';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Darlehen für Energiespeicher und PV Anlagen - BP-Center');
    this.seo.setMetaDesc('Günstige Energiedarlehen ohne Grundschuld - schnelle Entscheidungswege, Laufzeiten bis 20 Jahre - für Angestellt, Selbständige, Rentner, Beamte und Freiberufler.');
  }

}
