import { AppConfigService } from "./../../../services/app.config.service";
import { MailingService } from "./../../../services/mailing.service";
import { Component, OnInit, Input } from "@angular/core";
import { FormularService } from "../formular.service";
import { ModalDirective } from "ng-uikit-pro-standard";
import { Params, ActivatedRoute } from "@angular/router";

class CrmParameter {
  crmId: string;
  crmEmail: string;
  angebotssumme: string;
  stromrate: string;
}

export class LeasingAnfrage {
  name: string;
  rechtsform: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  unternehmenSeit: string;
  vertretenDurch: string;
  geb: string;
  kaufpreis: number;
  vertragslaufzeit: string;
  leasingobjekt: string;
  objektNeu: string;
  anzahl: number;
  sonderzahlung: number;
  erreichbarkeit: string;
  tel: string;
  email: string;
  crm: CrmParameter = new CrmParameter();
  ursprung: string;
  engineType: string;
  hersteller: string;
}

type enumValue = { value?: string; label?: string }[];

@Component({
  selector: "bpc-leasing-formular",
  templateUrl: "./leasing-formular.component.html"
})
export class LeasingFormularComponent implements OnInit {
  @Input() ursprung: string;

  anfrage: LeasingAnfrage;
  branding: string;
  status = "open";

  engineType: enumValue;

  constructor(
    private cs: AppConfigService,
    private fs: FormularService,
    private ar: ActivatedRoute,
    private ms: MailingService
  ) {
    this.anfrage = new LeasingAnfrage();
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.anfrage.ursprung = this.ursprung;
    this.engineType = this.cs.getEnum("engineType");

    this.ar.queryParams.subscribe(params => {
      this.fillFormByQueryParameter(params, "name", "name");
      this.fillFormByQueryParameter(params, "rechtsform", "rechtsform");
      this.fillFormByQueryParameter(params, "strasse", "strasse");
      this.fillFormByQueryParameter(params, "hausnummer", "hausnummer");
      this.fillFormByQueryParameter(params, "plz", "plz");
      this.fillFormByQueryParameter(params, "ort", "ort");
      this.fillFormByQueryParameter(params, "unternehmenSeit", "unternehmenSeit");
      this.fillFormByQueryParameter(params, "vertretenDurch", "vertretenDurch");
      this.fillFormByQueryParameter(params, "geb", "geb");
      this.fillFormByQueryParameter(params, "kaufpreis", "kaufpreis");
      this.fillFormByQueryParameter(params, "vertragslaufzeit", "vertragslaufzeit");
      this.fillFormByQueryParameter(params, "leasingobjekt", "leasingobjekt");
      this.fillFormByQueryParameter(params, "objektNeu", "objektNeu");
      this.fillFormByQueryParameter(params, "anzahl", "anzahl");
      this.fillFormByQueryParameter(params, "sonderzahlung", "sonderzahlung");
      this.fillFormByQueryParameter(params, "erreichbarkeit", "erreichbarkeit");
      this.fillFormByQueryParameter(params, "tel", "tel");
      this.fillFormByQueryParameter(params, "email", "email");

      this.fillFormByQueryParameter(params, "crmId", "crm.crmId");
      this.fillFormByQueryParameter(params, "crmEmail", "crm.crmEmail");
      this.fillFormByQueryParameter(params, "angebotssumme", "crm.angebotssumme");
      this.fillFormByQueryParameter(params, "stromrate", "crm.stromrate");
    });
  }

  fillFormByQueryParameter(
    params: Params,
    queryParam: string,
    target: string,
    targetEnum?: enumValue
  ) {
    if (params.hasOwnProperty(queryParam)) {
      var v = target.split(".");
      const val = decodeURI(params[queryParam]);
      let targetVal: string = null;

      if (targetEnum) {
        for (const e of targetEnum) {
          if (e.value === val.toString().toLowerCase()) {
            targetVal = e.value;
          }
        }
        if (!targetVal) return false;
      } else {
        targetVal = val;
      }

      if (v.length === 1) {
        this.anfrage[v[0]] = targetVal;
      } else if (v.length === 2) {
        this.anfrage[v[0]][v[1]] = targetVal;
      } else if (v.length === 3) {
        this.anfrage[v[0]][v[1]][v[2]] = targetVal;
      }
    }
  }

  onSubmit() {
    this.status = "pending";
    this.fs
      .sendLeasingAnfrage("leasing", this.branding, this.anfrage)
      .toPromise()
      .then(resp => {
        return this.ms.newInquiryInternal("Leasing").toPromise();
      })
      .then(() => {
        this.status = "sent";
      })
      .catch(error => {
        this.status = "error";
      });
  }

  onModalClose(modal: ModalDirective) {
    modal.hide();
    this.onSubmit();
  }
}
