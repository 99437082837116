import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bpc-testimonial',
  templateUrl: './testimonial.component.html'
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
