import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-forwarddarlehen',
  templateUrl: './forwarddarlehen.component.html',
  styleUrls: ['./forwarddarlehen.component.scss']
})
export class ForwarddarlehenComponent implements OnInit {

  constructor(private seo: SEOService) { }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Forwrarddarlehen - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Jetzt niedrige Zinsen für Ihre Anschlussfinanzierung sichern. Bis 60 Monate vor Zinsbindungsende.');
  }

}
