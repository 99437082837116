import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'bpc-tesla-slider',
  templateUrl: './tesla-slider.component.html'
})
export class TeslaSliderComponent implements OnInit {

  @Input() laufzeit: number;

  sliderForm: FormGroup;

  possibleDurations: { value: string, label: string }[] = [];
  sliderValues: [{ summe: number, rate: number, rest: number }[]?] = [];
  selectedSliderValues: { summe: number, rate: number, rest: number }[];
  currentValue: { summe: number, rate: number, rest: number };
  currentDuration: string;

  minSliderValue: number = 10000;
  maxSliderValue: number = 100000;
  sliderStep: number = 1000;
  sliderValue: number;

  status: string;

  constructor(private cs: AppConfigService) {
    this.status = 'pending';
  }

  ngOnInit() {
    this.sliderForm = new FormGroup({
      sliderInput: new FormControl()
    });

    this.cs.getTeslaSliderData().subscribe((data) => {

      for (let duration of data) {
        const index = this.sliderValues.push(duration.werte) - 1;
        this.possibleDurations.push({
          value: index.toString(), label: `${duration.laufzeit} Monate`
        });
      }

      this.selectedSliderValues = this.sliderValues[0];
      this.sliderValue = this.selectedSliderValues[0].summe;
      this.currentValue = this.selectedSliderValues[0];
      this.currentDuration = this.possibleDurations[0].value;

      this.sliderForm.addControl('sliderSelect', new FormControl(this.possibleDurations[0].value))

      this.status = 'ok';
    }, (_error: any) => {
      this.status = 'error';
    });
  }

  getSelectedValue(event: { value: string, label: string }) {
    this.selectedSliderValues = this.sliderValues[event.value];
    this.getCurrentSliderValue(this.sliderValue);
  }

  onRangeValueChange(event: number) {
    this.getCurrentSliderValue(event);
  }

  getCurrentSliderValue(amount: number) {
    for (let value of this.selectedSliderValues) {
      if (value.summe === amount)
        this.currentValue = value;
    }
  }

}
