import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  constructor(private seo: SEOService) { }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Wir beraten Sie umfassend zu allen Themen rund um die Finanzierung und Verwirklichung Ihrer Vorhaben.');
  }

}
