import { AppConfigService } from './../../../services/app.config.service';
import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-contracting-heizung',
  templateUrl: './one-pager-contracting-heizung.component.html'
})
export class OnePagerContractingHeizungComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Contracting von Wärmepumpen',
    'Contracting von BHKW',
    'Contracting von Holzpellet- oder Hackschnitzelanlagen',
    'Contracting von Solartermieanlagen,',
    'Contacting von Niedrigtemperaturtechnik',
    'Contracting von Brennstoffzellen und Brennwerttechnik'
  ];
  benefitPoints = [
    'monatlicher Beitrag - Planungssicherheit',
    'freie Wahl des Produktes und des Installateurs',
    'Schonung von Liquidität, Eigenkapital und Kreditlinie',
    '24 Stunden Notfallservice an 365 Tagen für Wartung und Instandhaltung',
    'Vollgarantie für die gesamte Laufzeit'
  ];
  headerTitle = 'Heizungsanlagen Contracting';
  headerSubTitle = 'Die neue Heizung im Rundum-Sorglos-Paket';
  headerImgSrc = 'assets/images/onePager_contracting_shutterstock_553462765.jpg';
  preHeading = 'Ihre neue Heizung für 0,00 € Anschaffungskosten';
  heading = 'Contracting von Heizungsanlagen';
  targetLink = 'contracting';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Contracting/Miete Heizungsanlagen - BP Center');
    this.seo.setMetaDesc('Wenn Sie sich für ein Contracting bzw. die Miete einer Heizungsanlage entscheiden, blebien Sie finanziell unabhängig und erhalten ein Rund-Um-Sorglospaket. Herstellerunabhängig.');
  }

}
