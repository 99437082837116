import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';

@Component({
  selector: 'bpc-one-pager-template',
  templateUrl: './one-pager-template.component.html',
  styleUrls: ['./one-pager-template.component.scss']
})
export class OnePagerTemplateComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() headerSubTitle: string;
  @Input() headerImgSrc: string;
  @Input() preHeading: string;
  @Input() heading: string;
  @Input() solutionPoints: string[];
  @Input() benefitPoints: string[];
  @Input() targetLink: string;

  branding: string;

  constructor(private cs: AppConfigService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
  }

}
