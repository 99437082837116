import { MailingService, NeueAnfrageEmail } from './../../../services/mailing.service';
import { AppConfigService } from './../../../services/app.config.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormularService } from '../formular.service';
import { ModalDirective } from 'ng-uikit-pro-standard';

export class KontaktAnfrage {
  anrede: string;
  vorname: string;
  name: string;
  tel: string;
  erreichbarkeit: string;
  email: string;
  requestCause: string;
  mitteilung: string;
  intModernisierung: boolean;
  intEnergiedarlehen: boolean;
  intProjektfinanzierung: boolean;
  intLeasingMietkauf: boolean;
  intBaufinanzierung: boolean;
  intForwarddarlehen: boolean;
  intGewerblFinanzierung: boolean;
  intContracting: boolean;
}

@Component({
  selector: 'bpc-kontakt-formular',
  templateUrl: './kontakt-formular.component.html'
})
export class KontaktFormularComponent implements OnInit {

  anfrage: KontaktAnfrage;
  branding: string;
  status = 'open';

  gender: {}[] | {value: string, label: string}[];

  constructor(private cs: AppConfigService, private fs: FormularService, private ms: MailingService) {
    this.anfrage = new KontaktAnfrage();
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.gender = this.cs.getEnum('gender');
  }

  onSubmit() {
    this.status = 'pending';
    this.fs.sendContactData('contact', this.branding, this.anfrage).toPromise().then((resp) => {
      return this.ms.confirmNewInquiryToCustomer(
        new NeueAnfrageEmail(
          this.anfrage.name, this.anfrage.email,
          this.anfrage.anrede, this.branding
        )
      ).toPromise();
    }).then(() => {
      return this.ms.newInquiryInternal('Kontaktanfrage').toPromise();
    }).then(() => {
      this.status = 'sent';
    }).catch((error) => {
      console.error('Fehler beim Versenden der Anfrage')
      console.error(error);
      this.status = 'error';
    });
  }

  onModalClose(modal: ModalDirective) {
    modal.hide();
    this.onSubmit();
  }

}
