import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-one-pager-leasing',
  templateUrl: './one-pager-leasing.component.html'
})
export class OnePagerLeasingComponent implements OnInit {

  branding: string;

  solutionPoints = [
    'Leasing von PV-Anlagen und Energiespeichern',
    'Leasing von LED Technik',
    'Leasing von Infarotheizungen',
    'Leasing von E-Autos und E-Ladestationen',
    'Leasing von Drucklufttechnik',
    'Leasing von PKW und Nutzfahrzeugen, Bussen u.v.a.m.'
  ];
  benefitPoints = [
    'sofortiger Kostenvorteil',
    'sichere Kalkulationsbasis',
    'Schonung von Liquidität, Eigenkapital und Kreditlinie',
    'Individuelle Vertragsgestaltung',
    'Herstellerunabhängige Finanzierung'
  ];
  preHeading = 'Gewinne werden nicht durch den Besitz von Anlagen und Maschienen erzielt sondern ausschließlich durch deren Nutzung';
  heading = 'Leasing';
  headerTitle = 'Leasing';
  headerImgSrc = 'assets/images/onePager_leasing_shutterstock_146626955.jpg';
  targetLink = 'leasing';

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Leasing PV Anlagen und Energiespeicher - BP-Center');
    this.seo.setMetaDesc('Leasing, Mietkauf- und Projektfinanzierungen mit voller Flexibilität in Sachen Laufzeit, Rate, Vertragsart und Serviceleistungen.');
  }

}
