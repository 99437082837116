import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  public map: any = { lat: 51.331947, lng: 12.370635 };

  constructor(private seo: SEOService) { }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Kontakt - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Kontakt zum Bau- & Projektfinanzierungs-Center.');
  }

}
