import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bpc-request-status',
  templateUrl: './request-status.component.html'
})
export class RequestStatusComponent implements OnInit {
  @Input() status: string;

  constructor() { }

  ngOnInit() {
  }

}
