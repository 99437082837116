import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-energiedarlehen',
  templateUrl: './energiedarlehen.component.html',
  styleUrls: ['./energiedarlehen.component.scss']
})
export class EnergiedarlehenComponent implements OnInit {

  branding: string;
  laufzeit = "20";

  constructor(private cs: AppConfigService, private seo: SEOService) {
    this.branding = cs.getBranding();
  }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Energiedarlehen bis 20 Jahre Laufzeit - BP-Center');
    this.seo.setMetaDesc('Günstige Energiedarlehen ohne Grundschuld - schnelle Entscheidungswege, Laufzeiten bis 20 Jahre - für Angestellt, Selbständige, Rentner, Beamte und Freiberufler.');
  }

}
