import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-datenschutzerklaerung',
  templateUrl: './datenschutzerklaerung.component.html'
})
export class DatenschutzerklaerungComponent implements OnInit {

  constructor(private seo: SEOService) { }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Datenschutzerklärung  - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Datenschutzerklärung des Bau- & Projektfinanzierungs-Centers.');
  }

}
