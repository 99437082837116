import { Component, OnInit } from '@angular/core';
import { SEOService } from './../../../services/seo.service';

@Component({
  selector: 'bpc-baufinanzierung',
  templateUrl: './baufinanzierung.component.html',
  styleUrls: ['./baufinanzierung.component.scss']
})
export class BaufinanzierungComponent implements OnInit {

  constructor(private seo: SEOService) { }

  ngOnInit() {
    this.seo.createLinkForCanonicalURL();
    this.seo.setPageTitle('Baufinanzierung - Bau- & Projektfinanzierungs-Center');
    this.seo.setMetaDesc('Neubau, Kauf, Sanierung -  Anbietervergleich ermöglicht beste Zinsen für Ihre individuelle Finanzierung. Sonderaktionen und Förderungen sowie regionale Besonderheiten berücksichtigen wir selbstverständlich.');
  }

}
