import { MailingService, NeueAnfrageEmail } from './../../../services/mailing.service';
import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';
import { FormularService } from '../formular.service';
import { FormControl } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute, Params } from '@angular/router';

class KontaktDaten {
  anrede: string;
  name: string;
  vorname: string;
  tel: string;
  erreichbarkeit: string;
  email: string;
  requestCause: string;
  angebotVorhanden: string;
  ansprechpartner: string;
  ansprechpartnerEmail: string;
  ansprechpartnerTel: string;
  angebotGewuenscht: string;
}

class VorhabenDaten {
  art: string;
  eigentumSeit: string;
  baujahr: string;
  finanzierungsbedarf: string;
  projektstart: string;
}

class LockedBy {
  _id: string;
  email: string;
  username: string;
  name: string;
}

class PersoenlicheDaten {
  geburtsdatum: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  familienstand: string;
  kinder: string;
  personenHaushalt: string;
  partner: PersoenlichePartnerDaten = new PersoenlichePartnerDaten();
}

class PersoenlichePartnerDaten {
  vorname: string;
  nachname: string;
  geburtsdatum: string;
}

class AusgabenDaten {
  kredite: string;
  sonstAus: string;
}

class EinkommenDaten {
  employment: string;
  lohn: string;
  sonstEin: string;
  partner: EinkommenPartnerDaten = new EinkommenPartnerDaten();
}

class EinkommenPartnerDaten {
  employment: string;
  lohn: string;
  sonstEin: string;
}

class CrmParameter {
  crmId: string;
  crmEmail: string;
  angebotssumme: string;
  stromrate: string;
}

export class KurzAnfrage {
  kontaktdaten: KontaktDaten = new KontaktDaten();
  vorhaben: VorhabenDaten = new VorhabenDaten();
  persoenliches: PersoenlicheDaten = new PersoenlicheDaten();
  einkommen: EinkommenDaten = new EinkommenDaten();
  ausgaben: AusgabenDaten = new AusgabenDaten();
  anmerkungen: string;
  ursprung: string;
  crm: CrmParameter = new CrmParameter();
}

type enumValue = { value?: string, label?: string }[]

@Component({
  selector: 'bpc-kurzanfrage-formular',
  templateUrl: './kurzanfrage-formular.component.html'
})
export class KurzanfrageFormularComponent implements OnInit {

  @Input() ursprung: string;

  anfrage: KurzAnfrage;
  branding: string;
  status = 'open';

  gender: enumValue;
  propertyType: enumValue;
  familyStatus: enumValue;
  professionalGroup: enumValue;

  constructor(
    private cs: AppConfigService,
    private fs: FormularService,
    private ms: MailingService,
    private ar: ActivatedRoute
  ) {
    this.branding = cs.getBranding();
    this.anfrage = new KurzAnfrage();
  }

  ngOnInit() {
    this.anfrage.ursprung = this.ursprung;
    this.gender = this.cs.getEnum('gender');
    this.propertyType = this.cs.getEnum('propertyType');
    this.familyStatus = this.cs.getEnum('familyStatus');
    this.professionalGroup = this.cs.getEnum('professionalGroup');

    this.ar.queryParams.subscribe((params) => {
      
      this.fillFormByQueryParameter(params, 'anrede', 'kontaktdaten.anrede', this.gender);
      this.fillFormByQueryParameter(params, 'vorname', 'kontaktdaten.vorname');
      this.fillFormByQueryParameter(params, 'name', 'kontaktdaten.name');
      this.fillFormByQueryParameter(params, 'tel', 'kontaktdaten.tel');
      this.fillFormByQueryParameter(params, 'erreichbarkeit', 'kontaktdaten.erreichbarkeit');
      this.fillFormByQueryParameter(params, 'email', 'kontaktdaten.email');
      this.fillFormByQueryParameter(params, 'finanzierungsgrund', 'kontaktdaten.requestCause');
      this.fillFormByQueryParameter(params, 'angebotVorhanden', 'kontaktdaten.angebotVorhanden');
      this.fillFormByQueryParameter(params, 'ansprechpartner', 'kontaktdaten.ansprechpartner');
      this.fillFormByQueryParameter(params, 'ansprechpartnerEmail', 'kontaktdaten.ansprechpartnerEmail');
      this.fillFormByQueryParameter(params, 'ansprechpartnerTel', 'kontaktdaten.ansprechpartnerTel');
      this.fillFormByQueryParameter(params, 'angebotGewuenscht', 'kontaktdaten.angebotGewuenscht');

      this.fillFormByQueryParameter(params, 'vorhabenArt', 'vorhaben.art', this.propertyType);
      this.fillFormByQueryParameter(params, 'vorhabenBaujahr', 'vorhaben.baujahr');
      this.fillFormByQueryParameter(params, 'vorhabenEigentumSeit', 'vorhaben.eigentumSeit');
      this.fillFormByQueryParameter(params, 'vorhabenFinanzierungsbedarf', 'vorhaben.finanzierungsbedarf');
      this.fillFormByQueryParameter(params, 'vorhabenProjektstart', 'vorhaben.projektstart');

      this.fillFormByQueryParameter(params, 'persGeb', 'persoenliches.geburtsdatum');
      this.fillFormByQueryParameter(params, 'persStrasse', 'persoenliches.strasse');
      this.fillFormByQueryParameter(params, 'persHausnummer', 'persoenliches.hausnummer');
      this.fillFormByQueryParameter(params, 'persPlz', 'persoenliches.plz');
      this.fillFormByQueryParameter(params, 'persOrt', 'persoenliches.ort');
      this.fillFormByQueryParameter(params, 'persFamilienstand', 'persoenliches.familienstand', this.familyStatus);
      this.fillFormByQueryParameter(params, 'persKinder', 'persoenliches.kinder');
      this.fillFormByQueryParameter(params, 'persHaushalt', 'persoenliches.personenHaushalt');

      this.fillFormByQueryParameter(params, 'partnerVorname', 'persoenliches.partner.vorname');
      this.fillFormByQueryParameter(params, 'partnerName', 'persoenliches.partner.nachname');
      this.fillFormByQueryParameter(params, 'partnerGeb', 'persoenliches.partner.geburtsdatum');

      this.fillFormByQueryParameter(params, 'einnahmenBeschaeftigung', 'einkommen.employment', this.professionalGroup);
      this.fillFormByQueryParameter(params, 'einnahmenLohn', 'einkommen.lohn');
      this.fillFormByQueryParameter(params, 'einnahmenSonst', 'einkommen.sonstEin');

      this.fillFormByQueryParameter(params, 'einnahmenPartnerBeschaeftigung', 'einkommen.partner.employment', this.professionalGroup);
      this.fillFormByQueryParameter(params, 'einnahmenPartnerLohn', 'einkommen.partner.lohn');
      this.fillFormByQueryParameter(params, 'einnahmenPartnerSonst', 'einkommen.partner.sonstEin');

      this.fillFormByQueryParameter(params, 'ausgabenKredite', 'ausgaben.kredite');
      this.fillFormByQueryParameter(params, 'ausgabenSonst', 'ausgaben.sonstAus');

      this.fillFormByQueryParameter(params, 'anmerkung', 'anmerkungen');

      this.fillFormByQueryParameter(params, 'crmId', 'crm.crmId');
      this.fillFormByQueryParameter(params, 'crmEmail', 'crm.crmEmail');
      this.fillFormByQueryParameter(params, 'angebotssumme', 'crm.angebotssumme');
      this.fillFormByQueryParameter(params, 'stromrate', 'crm.stromrate');

    });
  }

  fillFormByQueryParameter(params: Params, queryParam: string, target: string, targetEnum?: enumValue) {
    if (params.hasOwnProperty(queryParam)) {
      var v = target.split('.');
      const val =  decodeURI(params[queryParam]);
      let targetVal: string = null;

      if(targetEnum){
        for (const e of targetEnum) {
          if(e.value === val.toString().toLowerCase()){
            targetVal = e.value;}
        }
        if(!targetVal)
          return false;
      } else {
        targetVal = val;
      }

      if(v.length === 1){
        this.anfrage[v[0]] = targetVal;
      } else if (v.length === 2){
        this.anfrage[v[0]][v[1]] = targetVal;
      } else if (v.length === 3){
        this.anfrage[v[0]][v[1]][v[2]] = targetVal;
      }
    }
  }

  onSubmit() {
    this.status = 'pending';
    this.fs.sendShortData('short', this.branding, this.anfrage).toPromise().then((resp) => {
      return this.ms.confirmNewInquiryToCustomer(
        new NeueAnfrageEmail(
          this.anfrage.kontaktdaten.name, this.anfrage.kontaktdaten.email,
          this.anfrage.kontaktdaten.anrede, this.branding
        )
      ).toPromise();
    }).then(() => {
      return this.ms.newInquiryInternal(this.ursprung).toPromise();
    }).then(() => {
      this.status = 'sent';
    }).catch((error) => {
      this.status = 'error';
    });
  }

  onModalClose(modal: ModalDirective) {
    modal.hide();
    this.onSubmit();
  }

}
