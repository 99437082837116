import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bpc-schufa-content',
  templateUrl: './schufa-content.component.html'
})
export class SchufaContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
