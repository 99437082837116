import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from '../../../services/app.config.service';

@Component({
  selector: 'bpc-slider',
  templateUrl: './slider.component.html'
})
export class SliderComponent implements OnInit {

  @Input() laufzeit: number;

  sliderValue: number;
  minSliderValue: number;
  maxSliderValue: number;
  sliderStep: number;
  status: string;
  currentValue;
  data = [];

  constructor(private cs: AppConfigService) {
    this.status = 'pending';
  }

  ngOnInit() {
    this.cs.getSliderData(this.laufzeit).subscribe((data: any) => {
      this.data = data;

      this.minSliderValue = this.data[0].darlehen;
      this.maxSliderValue = this.data[this.data.length - 1].darlehen;
      this.sliderValue = this.minSliderValue;
      this.sliderStep = Math.round((this.maxSliderValue - this.minSliderValue) / (this.data.length - 1));

      this.currentValue = this.data[0];

      this.status = 'ok';
    }, (error: any) => {
      this.status = 'error';
    });
  }

  getCurrentValue() {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].darlehen === this.sliderValue) {
        this.currentValue = this.data[i];
        return this.currentValue.darlehen;
      }
    }
    return 0;
  }

}
