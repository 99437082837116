import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { AppConfigService } from './app.config.service';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: Document,
    private cs: AppConfigService
  ) { }

  setPageTitle(title: string) {
    this.title.setTitle(title);
  }

  getPageTitle() {
    return this.title.getTitle();
  }

  setMetaDesc(desc: string) {
    this.meta.updateTag({name: 'description', content: desc});
  }

  createLinkForCanonicalURL() {

    let docUrl = (<string>this.doc.URL);

    if (!docUrl.startsWith('http')) {
      docUrl = this.cs.getFrontendUrl() + docUrl;
    }

    let url = docUrl.replace(this.cs.getBranding(), 'bpc');

    if (this.isUrlBaseUrl(url))
      url = url + 'bpc';

    const linkEl = this.getCanonicalLinkNode(this.doc.head.children);

    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);

    if (linkEl) {
      this.doc.head.replaceChild(link, linkEl);
    } else {
      this.doc.head.appendChild(link);
    }

  }

  isUrlBaseUrl(url: string) {
    return url === 'https://www.bp-center.de/' || url === 'http://localhost:4201/'
  }

  getCanonicalLinkNode(nodes: HTMLCollection): Element | undefined {
    for (const node in nodes) {
      if (nodes.hasOwnProperty(node)) {
        const element = nodes[node];
        if (element.tagName === 'LINK' && element.hasAttribute('rel') && element.getAttribute('rel') === 'canonical') {
          return element;
        }
      }
    }
    return undefined;
  }
}
